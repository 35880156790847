import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useAuth } from '../context/AuthContext'

type MenuItem = {
  label: string
  icon: React.ReactNode
  path: string
}

const menuItems: MenuItem[] = [
  {
    label: 'Dashboard',
    icon: <Icon icon="mage:dashboard-fill" width="24" height="24" />,
    path: '/dashboard',
  },

  {
    label: 'Transactions',
    icon: <Icon icon="tdesign:undertake-transaction" width="24" height="24" />,
    path: '/transactions',
  },
 
]

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { logout } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    const handleResize = () => setIsMobile(mediaQuery.matches)

    handleResize() // Initialize on mount
    mediaQuery.addEventListener('change', handleResize)

    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [])

  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true) // Always collapsed on mobile
    }
  }, [isMobile])

  const handleLogout = () => {
    logout()
    navigate('/sign-in')
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`${
          isCollapsed ? 'w-20' : 'w-64'
        } bg-white shadow-lg flex flex-col justify-between transition-all duration-300`}
      >
        {/* Navigation Menu */}
        <div>
          <div className="flex justify-between items-center p-4">
            {!isCollapsed && !isMobile && (
              <img
                src="/images/Blouza-logo.svg"
                alt="logo"
                className="w-[100px] h-16"
              />
            )}
            {!isMobile && (
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="text-gray-600 hover:text-gray-800 flex items-center justify-center rounded-full p-2 bg-gray-200"
              >
                <Icon
                  icon={
                    isCollapsed
                      ? 'mdi-light:chevron-double-left'
                      : 'mdi-light:chevron-double-right'
                  }
                  width="20"
                  height="20"
                />
              </button>
            )}
          </div>
          <nav className="space-y-2 mt-8">
            {menuItems.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                className={({ isActive }) =>
                  `flex items-center px-4 py-2 text-gray-600 transition-all ${
                    isActive
                      ? 'bg-gradient-to-r from-[#0061FF] to-white text-white'
                      : 'hover:bg-gray-200'
                  }`
                }
                aria-label={item.label}
              >
                <span className="mr-3">{item.icon}</span>
                {!isCollapsed && <span>{item.label}</span>}
              </NavLink>
            ))}
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 overflow-y-auto bg-[#F2F2F2] p-2.5 md:p-5">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center rounded-[10px] bg-white p-4 ">
            <div>
              <span className="text-[#030229] opacity-40 text-[22px]">
                Welcome Back,{' '}
              </span>
            </div>

            <div className="flex items-center gap-3.5">
              <button onClick={handleLogout} title="logout">
                <Icon
                  icon="majesticons:logout"
                  width="24"
                  height="24"
                  className="text-[#030229] opacity-40"
                />
              </button>
            </div>
          </div>
        </div>
        {children}
      </main>
    </div>
  )
}

export default Layout
