import React, { useState } from 'react'
import { Table, Pagination, TableProps } from 'antd'
import Layout from '../../layout/ManagementHubLayout'
import HeaderSection from '../../component/common/HeaderSection'
import { useVerificationLogs } from '../../api/hooks/useVerificationLogs'
import { ColumnType } from 'antd/es/table'
import { VerificationLog } from '../../types/types'

const Transaction: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const { data, isLoading, error } = useVerificationLogs(currentPage, pageSize)

  const handlePaginationChange = (page: number, size: number): void => {
    setCurrentPage(page)
    setPageSize(size)
  }

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: VerificationLog, b: VerificationLog) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Agent Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
      sorter: (a: VerificationLog, b: VerificationLog) =>
        a.agent_name.localeCompare(b.agent_name),
    },
    {
      title: 'Type',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Successful', value: 'success' },
        { text: 'Failed', value: 'failed' },
      ],
      onFilter: (value: string | number | boolean, record: VerificationLog) =>
        record.status === value,
      render: (status: 'success' | 'failed') => (
        <span
          className={`px-5 py-2.5 text-sm font-medium rounded-full ${
            status === 'success'
              ? 'bg-green-100 text-[#00B69B]'
              : 'bg-red-100 text-[#B60000]'
          }`}
        >
          {status === 'success' ? 'Successful' : 'Failed'}
        </span>
      ),
    },
  ]

  // Handle error state
  if (error) {
    return (
      <Layout>
        <HeaderSection title="Transactions" />
        <div className="text-red-500">
          Error loading transactions: {error.message}
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <HeaderSection title="Transactions" />

      {/* Table Section */}
      <div className="bg-white rounded-lg shadow">
        <Table<VerificationLog>
          columns={columns as ColumnType<VerificationLog>[]}
          dataSource={data?.response?.data || []}
          rowKey={(record) => record.id}
          loading={isLoading}
          pagination={false}
          scroll={{ x: true }}
        />

        {/* Pagination Section */}
        <div className="flex justify-between items-center p-4 border-t">
          <div className="text-sm text-gray-600">
            <span className="border-b border-[#0061FF]">
              {data?.response?.total ?? 0}
            </span>
            <span className="ml-2">
              Items shown | Page {currentPage} of{' '}
              {Math.ceil((data?.response?.total ?? 0) / pageSize)}
            </span>
          </div>

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data?.response?.total ?? 0}
            onChange={handlePaginationChange}
            showSizeChanger
            showQuickJumper
            showTotal={(total: number) => `Total ${total} items`}
            className="text-sm"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Transaction
