import { useQuery } from 'react-query'
import axiosInstance from '../axiosInstance'
import { VerificationLog } from '../../types/types'

interface VerificationResponse {
  data: VerificationLog[]
  total: number
  current_page: number
  per_page: number
}

interface QueryResponse {
  response: VerificationResponse
  message: string
  status: boolean
}

const fetchVerificationLogs = async ({
  page = 1,
  perPage = 10,
}): Promise<QueryResponse> => {
  try {
    const { data } = await axiosInstance.get('/verification', {
      params: {
        per_page: perPage,
        page,
      },
    })
    return data
  } catch (error) {
    throw new Error('Failed to fetch verification logs')
  }
}

export const useVerificationLogs = (page: number, perPage: number) => {
  return useQuery<QueryResponse, Error>(
    ['verificationLogs', page, perPage],
    () => fetchVerificationLogs({ page, perPage }),
    {
      keepPreviousData: true,
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  )
}
