import { useMutation } from 'react-query'
import axiosInstance from '../axiosInstance'

const verifyNin = async (nin: string) => {
  const { data } = await axiosInstance.post('/verification/nin', {
    nin,
    reference: null,
  })

  if (!data.status) {
    const errorMessage = Array.isArray(data.response)
      ? data.response
          .map((err: any) => `${err.field}: ${err.message}`)
          .join(', ')
      : data.message

    throw new Error(errorMessage)
  }

  return data
}

export const useNinVerification = () => {
  return useMutation(verifyNin)
}
