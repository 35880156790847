import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './page/Home'
import Products from './page/Products'
import Service from './page/Service'
import RevenueManagementSystemDetails from './page/RevenueManagementSystemDetails'
import SignIn from './page/auth/SignIn'
import Dashboard from './page/ManagementHub/Dashboard'
import Transaction from './page/ManagementHub/Transactions'
import { AuthProvider } from './context/AuthContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toaster } from 'react-hot-toast'

const queryClient = new QueryClient()

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" reverseOrder={false} />
        <Router>
          <div>
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/services" element={<Service />} />
              <Route
                path="/revenue-management-system"
                element={<RevenueManagementSystemDetails />}
              />

              {/* MANAGEMENT HUB */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/transactions" element={<Transaction />} />
            </Routes>
          </div>
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default App
