import { useState } from 'react'
import { useNinVerification } from '../../api/hooks/useNinVerification'
import { useVerifyVNIN } from '../../api/hooks/verifyVNinApi'
import Layout from '../../layout/ManagementHubLayout'
import { IUserData } from '../../types/types'

const Dashboard = () => {
  const [nin, setNin] = useState('')
  const [isVNIN, setIsVNIN] = useState(false)
  const [ninData, setNinData] = useState<IUserData | null>(null)
  const [vninData, setVninData] = useState<IUserData | null>(null)
  const [verificationError, setVerificationError] = useState('')

  const { mutate: verifyNin, isLoading: isLoadingNIN } = useNinVerification()
  const { mutate: verifyVNin, isLoading: isLoadingVNIN } = useVerifyVNIN()

  const handleVerification = () => {
    if (!nin.trim()) {
      setVerificationError(`Please enter a valid ${isVNIN ? 'vNIN' : 'NIN'}.`)
      return
    }

    if (isVNIN) {
      if (!/^[A-Za-z0-9]{16}$/.test(nin)) {
        setVerificationError('vNIN must be exactly 16 alphanumeric characters.')
        return
      }
    } else if (!/^\d{11}$/.test(nin)) {
        setVerificationError('NIN must be exactly 11 digits.')
        return
      }

    setVerificationError('')

    if (isVNIN) {
      setVninData(null)
    } else {
      setNinData(null)
    }

    const verifyFn = isVNIN ? verifyVNin : verifyNin

    verifyFn(nin, {
      onSuccess: (data) => {
        if (!data.status) {
          let errorMessage = data.message || 'Validation not passed.'
          if (Array.isArray(data.response)) {
            errorMessage = data.response
              .map((err: any) => `${err.field}: ${err.message}`)
              .join(', ')
          }
          setVerificationError(errorMessage)
          return
        }

        if (isVNIN) {
          setVninData(data.response)
        } else {
          setNinData(data.response)
        }
      },
      onError: (error: any) => {
        let errorMessage = 'Verification failed. Please try again.'
        if (error.response?.data) {
          const errorData = error.response.data
          if (!errorData.status && Array.isArray(errorData.response)) {
            errorMessage = errorData.response
              .map((err: any) => `${err.field}: ${err.message}`)
              .join(', ')
          } else {
            errorMessage = errorData.message || errorMessage
          }
        }
        setVerificationError(errorMessage)
      },
    })
  }

  return (
    <Layout>
      <div className="max-w-4xl mx-auto p-6 space-y-8">
        <div className="flex items-center gap-4">
          <span
            className={`text-sm font-medium ${
              !isVNIN ? 'text-blue-600' : 'text-gray-500'
            }`}
          >
            NIN
          </span>
          <button
            onClick={() => setIsVNIN((prev) => !prev)}
            className="relative w-10 h-5 bg-gray-300 rounded-full transition-all"
          >
            <span
              className={`absolute top-1 left-1 w-3.5 h-3.5 bg-white rounded-full shadow transition-transform ${
                isVNIN ? 'translate-x-5' : ''
              }`}
            />
          </button>
          <span
            className={`text-sm font-medium ${
              isVNIN ? 'text-blue-600' : 'text-gray-500'
            }`}
          >
            vNIN
          </span>
        </div>

        <div className="flex gap-4">
          <input
            type="text"
            placeholder={isVNIN ? '16 Character vNIN' : '11 Digit NIN'}
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            className="flex-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <button
            onClick={handleVerification}
            disabled={isLoadingNIN || isLoadingVNIN}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-gray-400"
          >
            {isLoadingNIN || isLoadingVNIN ? 'Verifying...' : 'Verify'}
          </button>
        </div>

        {verificationError && (
          <p className="text-red-500 mt-2">{verificationError}</p>
        )}

        {(isVNIN ? vninData : ninData) && (
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex-shrink-0">
                <img
                  src={
                    (isVNIN ? vninData : ninData)?.photo_full_path ||
                    '/images/placeholder-face-big.png'
                  }
                  alt="Profile"
                  className="w-48 h-48 object-cover rounded-lg"
                />
              </div>
              <div className="flex-1 space-y-4">
                {[
                  {
                    label: 'Surname',
                    value: isVNIN ? vninData?.surname : ninData?.surname,
                  },
                  {
                    label: 'First Name',
                    value: isVNIN ? vninData?.first_name : ninData?.first_name,
                  },
                  {
                    label: 'Middle Name',
                    value: isVNIN
                      ? vninData?.middle_name
                      : ninData?.middle_name,
                  },
                  {
                    label: 'Date Of Birth',
                    value: isVNIN
                      ? vninData?.date_of_birth
                      : ninData?.date_of_birth,
                  },
                  {
                    label: 'Gender',
                    value: isVNIN ? vninData?.gender : ninData?.gender,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-2 py-2 border-b border-gray-200 last:border-0"
                  >
                    <span className="text-gray-600 font-medium">
                      {item.label}
                    </span>
                    <span className="text-gray-900">{item.value}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Dashboard
