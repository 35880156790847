import { Form, Input, Button } from 'antd'
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from '@ant-design/icons'
import { useLogin } from '../../api/hooks/useLogin'
import { motion } from 'framer-motion'

const SignIn = () => {
  const { login, loginStatus } = useLogin()

  const onFinish = (values: { email: string; password: string }) => {
    login(values)
  }

  return (
    <div
      className="min-h-screen w-full flex items-center justify-center p-4"
      style={{
        backgroundImage: "url('/images/blouza-signinImg.svg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="w-full max-w-md bg-white rounded-[13px] shadow-lg p-8 border">
        {/* Logo Section */}
        <div className="flex flex-col items-center mb-2.5">
          <div className="flex items-center justify-center">
            <img src="/images/Blouza-logo.svg" alt="Blouza Logo" />
          </div>
          <h2 className="text-2xl text-[#122676] font-poppins font-[500]">
            Sign In
          </h2>
        </div>

        {/* Form Section */}
        <Form
          name="signin"
          onFinish={onFinish}
          layout="vertical"
          className="space-y-4 w-full"
        >
          <Form.Item
            name="email"
            label={
              <span className="text-[#666666] font-poppins text-[11px] font-[400]">
                Email
              </span>
            }
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input
              prefix={<UserOutlined className="text-slate-400" />}
              placeholder="Email Address"
              size="large"
              className="rounded-md"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={
              <div className="flex items-center gap-5 w-full justify-between">
                <span className="text-[#666666] font-poppins text-[11px] font-[400]">
                  Password
                </span>
                <span className="text-[#111111] underline text-[11px] font-poppins cursor-pointer">
                  Forgot Password?
                </span>
              </div>
            }
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-slate-400" />}
              placeholder="Password"
              size="large"
              className="rounded-md"
            />
          </Form.Item>

          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                loginStatus === 'loading' || loginStatus === 'Logged In'
              }
              className="h-[44px] w-full md:w-[400px]"
              style={{
                background: 'linear-gradient(to right, #0A2FB6, #4964DB)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <motion.span
                key={loginStatus}
                initial={{ opacity: 0, y: -15 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 15 }}
                transition={{ duration: 0.075 }}
                className="flex items-center justify-center gap-1 text-white"
              >
                {loginStatus === 'Logged In' && (
                  <>
                    <motion.span
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.075, type: 'spring' }}
                    >
                      <CheckCircleFilled
                        style={{
                          fontSize: '16px',
                          marginRight: '8px',
                          color: 'white',
                        }}
                      />
                    </motion.span>
                  </>
                )}

                {loginStatus === 'loading' ? (
                  <LoadingOutlined
                    style={{
                      fontSize: '16px',
                      marginRight: '8px',
                      color: 'white',
                    }}
                    spin
                  />
                ) : (
                  loginStatus
                )}
              </motion.span>
            </Button>
          </Form.Item>
        </Form>

        <div className="flex items-center justify-between mt-2.5">
          <span className="text-[11px] font-poppins text-[500] text-[#4964DB]">
            Powered by Blouza Technologies Limited
          </span>
          <span className="text-[11px] font-poppins text-[500] text-[#4964DB]">
            All Rights Reserved. 2025
          </span>
        </div>
      </div>
    </div>
  )
}

export default SignIn
